import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { compile } from "path-to-regexp";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Layout from "../../../components/Layout";
import Card from "../../../components/Card";
import CardEmpty from "../../../components/CardEmpty";
import CardSection from "../../../components/CardSection";
import List from "../../../components/List";
import Table from "../../../components/Table";
import Text from "../../../components/Text";
import Link from "../../../components/Link";
import { WhiteButton, PrimaryButton } from "../../../components/Button";
import { countryCode, routes } from "../../../config";
import { quotesColumns, policiesColumns } from "../config";
import * as moduleActions from "../actions";
import * as authActions from "../../Auth/actions";

const Button = styled(PrimaryButton)`
  margin: 1.625em auto 0;
  max-width: 18.875em;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 40em) {
    display: block;
    a {
      margin-left: 1em;
    }
  }
`;

const HelpText = P => (
  <P>
    Here you can save, amend and refresh quotes as well as store, view and
    manage your documents.
  </P>
);

const getStatusLabel = (status) => status === 'Expired' ? 'Complete' : status;

const Dashboard = ({
  fetchPolicies,
  fetchQuotes,
  fetchUser,
  policiesData,
  quotesData,
}) => {
  const isIE = countryCode === "IE";
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);

  const initialFetch = async () => {
    if (!isIE) {
      await fetchQuotes();
    }
    await fetchPolicies();
    await fetchUser();

    setInitialFetchComplete(true);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const hasQuotesData = quotesData && quotesData.length > 0;
  const hasPoliciesData = policiesData && policiesData.length > 0;
  const button = isIE ? null : (
    <Link to={routes.quote} decoration="none">
      <WhiteButton width="11.438em" tansparent>
        New Quote
      </WhiteButton>
    </Link>
  );

  const mappedQuotesRows = quotesData.map(row => {
    const viewPath = compile(routes.viewQuote)({ id: row.id });
    const editPath = compile(routes.editQuote)({ id: row.id });
    const color = row.status === "Active" ? "#82BC00" : "#D73333";
    const status = (
      <Text weight="semibold" color={color}>
        {getStatusLabel(row.status)}
      </Text>
    );

    const link =
      row.status === "Active" ? (
        <LinkWrapper>
          <Link to={viewPath}>View</Link>
          <Link to={editPath}>Edit</Link>
        </LinkWrapper>
      ) : null;

    return { ...row, status, link };
  });

  const mappedPoliciesRows = policiesData.map(row => {
    const color = row.status === "Active" ? "#82BC00" : "#D73333";
    const path = compile(routes.viewPolicy)({ id: row.id });
    const link = <Link to={path}>View</Link>;
    const status = (
      <Text weight="semibold" color={color}>
        {getStatusLabel(row.status)}
      </Text>
    );

    return { ...row, status, link };
  });

  return (
    <Layout
      headerText="Dashboard"
      button={button}
      currentRoute={routes.dashboard}
      isLoading={!isInitialFetchComplete}
    >
      <List>
        {hasPoliciesData && (
        <Card headerText="My Policies">
          <CardSection>
            <Table
              rows={mappedPoliciesRows}
              columns={policiesColumns}
              isZebraStriped
            />
          </CardSection>
        </Card>
        )}
        {hasQuotesData && !isIE && (
          <Card headerText="My Quotes" renderHelpText={HelpText}>
            <CardSection>
              <Table
                rows={mappedQuotesRows}
                columns={quotesColumns}
                isZebraStriped
              />
            </CardSection>
          </Card>
        )}
        {!hasQuotesData && !isIE && (
          <CardEmpty headerText="My Quotes">
            <Text color="#6B6B6B" align="center" maxWidth="28.875em">
              You have no quotes yet! Click the button below to get a quote on
              your treatment.
            </Text>
            <Link to={routes.quote} decoration="none">
              <Button>Get a Quote</Button>
            </Link>
          </CardEmpty>
        )}
      </List>
    </Layout>
  );
};

const mapStateToProps = state => ({
  policiesData: state.policies.data,
  quotesData: state.quotes.data,
});

const mapDispatchToProps = {
  fetchPolicies: moduleActions.fetchPolicies,
  fetchQuotes: moduleActions.fetchQuotes,
  fetchUser: authActions.fetchUser,
};

Dashboard.propTypes = {
  fetchPolicies: PropTypes.func.isRequired,
  fetchQuotes: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  policiesData: PropTypes.array.isRequired,
  quotesData: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
