import React from "react";
import { render } from "react-dom";
import { connect, Provider } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/browser";

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import { Home } from "./pages/Home";
import { ClinicHunter } from "./pages/ClinicHunter";
import { Cdt } from "./pages/Cdt";
import { AccountSettings } from "./pages/AccountSettings";
import { Registration } from "./pages/Registration";
import { ViewPolicy } from "./pages/ViewPolicy";
import { CoverCheck } from "./pages/CoverCheck";
import { ViewQuote } from "./pages/ViewQuote";
import { AcceptBuy } from "./pages/AcceptBuy";
import { Dashboard } from "./pages/Dashboard";
import { ResetPassword } from "./pages/ResetPassword";
import NotFound from "./pages/NotFound";
import store from "./store";
import theme from "./themes";
import { countryCode, routes, stripeApiKey } from "./config";
import history from "./history";
import "./style.css";
import SessionTimeoutModal from "./components/SessionTimeoutModal";
import { unsetToken as unsetTokenAction } from "./pages/Auth";

const stripePromise = loadStripe(stripeApiKey);

const rootElement = document.getElementById("root");

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.APP_ENV,
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
});

Sentry.configureScope(scope => {
  scope.setTag("country", process.env.COUNTRY_CODE);
});

window.Sentry = Sentry;

function scrollTop() {
  if (this.state.location.action === "PUSH") {
    window.scrollTo(0, 0);
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const isLoggedIn = Boolean(store.getState().auth.token);

      if (isLoggedIn) {
        return <Component {...props} />;
      }

      return <Redirect to={routes.home} />;
    }}
  />
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, showTokenExpiryModal: false };

    this.handleTokenRefreshMessage = this.handleTokenRefreshMessage.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    document.addEventListener("refreshToken", this.handleTokenRefreshMessage);
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  componentWillUnmount() {
    document.removeEventListener(
      "refreshToken",
      this.handleTokenRefreshMessage,
    );
  }

  handleTokenRefreshMessage() {
    this.setState(prevState => ({
      ...prevState,
      showTokenExpiryModal: true,
    }));
  }

  handleReturn() {
    const { unsetToken } = this.props;
    this.setState(prevState => ({
      ...prevState,
      showTokenExpiryModal: false,
    }));

    unsetToken();
    history.push("/");
    window.location.reload();
  }

  render() {
    const { showTokenExpiryModal } = this.state;
    const { children } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <>
          {showTokenExpiryModal && (
            <SessionTimeoutModal onConfirm={this.handleReturn} />
          )}
          {children}
        </>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = {
  unsetToken: unsetTokenAction,
};

const ErrorBoundaryComponent = connect(
  null,
  mapDispatchToProps,
)(ErrorBoundary);

render(
  <Provider store={store}>
    <ErrorBoundaryComponent>
      <Router history={history}>
        <Switch>
          <PrivateRoute path={routes.dashboard} component={Dashboard} />
          <PrivateRoute
            path={routes.accountSettings}
            component={AccountSettings}
          />
          <Route path={routes.registration} component={Registration} />
          {countryCode !== 'IE' && (
            <Route
              path={routes.editQuote}
              render={props => <CoverCheck {...props} isEditing />}
            />
          )}
          {countryCode !== 'IE' && (
            <Route path={routes.viewQuote} component={ViewQuote} />
          )}
          {countryCode !== 'IE' && (
            <Route
              path={routes.buyQuote}
              render={props => (
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <AcceptBuy {...props} stripe={stripe} elements={elements} />
                    )}
                  </ElementsConsumer>
                </Elements>
              )}
            />
          )}
          {countryCode !== 'IE' && (
            <Route path={routes.quote} component={CoverCheck} />
          )}
          {countryCode !== 'IE' && (
            <Route path={routes.registerQuote} component={Registration} />
          )}
          <Route path={routes.viewPolicy} component={ViewPolicy} />
          <Route path={routes.resetPassword} component={ResetPassword} />
          <Route path={routes.notFound} component={NotFound} />
          <Route exact path={routes.home} component={Home} />
          <Route exact path={routes.clinicHunter} component={ClinicHunter} />
          <Route exact path={routes.cdt} component={Cdt} />
          <Redirect from="*" to={routes.notFound} />
        </Switch>
      </Router>
    </ErrorBoundaryComponent>
  </Provider>,
  rootElement,
);
