import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import Header from "../../../components/Header";
import { H1 } from "../../../components/Heading";
import { CtaButton, SecondaryButton } from "../../../components/Button";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputField from "../../../components/InputField";
import List from "../../../components/List";
import Text from "../../../components/Text";
import Link from "../../../components/Link";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Form from "../../../components/Form";
import Footer from "../../../components/Footer";
import * as modalActions from "../../Modal/actions";
import * as authActions from "../../Auth/actions";
import * as snackbarActions from "../../Snackbar/actions";
import * as quoteActions from "../../CoverCheck/actions";
import { Snackbar } from "../../Snackbar";
import {
  getFormData,
  getUrlParamsAsString,
  setQueryParamValueInSessionStorageIfNeeded,
} from "../../../helpers";
import { countryCode, routes, documents } from "../../../config";

const Wrapper = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 4em;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -4.625em auto 0;
  padding: 0 1em;
`;

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36.25em;
  min-height: 36.25em;
`;

const HeroInner = styled.div`
  margin: 0 auto;
  max-width: 44.5em;
  text-align: center;
`;

const Home = ({
  fetchUser,
  history,
  isLoading,
  location,
  loginForm,
  saveQuote,
  showModal,
  showSnackbar,
  submitLoginForm,
}) => {
  const query = queryString.parse(location.search);
  const isAU = countryCode === "AU";
  const isUK = countryCode === "UK";
  const isIE = countryCode === "IE";

  const showForgotPasswordModal = () => showModal("ModalForgotPassword");

  const onLoginFormSubmit = async event => {
    const formData = getFormData(event.target);

    try {
      await submitLoginForm({ data: formData });
      await fetchUser();

      if (query.quote_id && query.quote_token) {
        await saveQuote({ id: query.quote_id, data: query });
      }

      history.push(routes.dashboard);
    } catch (err) {
      showSnackbar("error", "Invalid credentials");

      throw err;
    }
  };

  setQueryParamValueInSessionStorageIfNeeded('affiliate');

  return (
    <Wrapper>
      <Header>
        {!isIE && (
          <HeroWrapper>
            <HeroInner>
              <H1 marginBottom="0.640em" align="center">
                Get a Quote
              </H1>
              {isUK && (
                <Text
                  size="1.125em"
                  marginBottom="1.778em"
                  lineHeight="1.611em"
                  align="center"
                >
                  Medical Travel Shield is travel insurance designed for patients
                  travelling abroad for either Dental Treatment, Cosmetic surgery,
                  Elective surgery or Fertility Treatment.
                </Text>
              )}
              {isAU && (
                <Text
                  size="1.125em"
                  marginBottom="1.778em"
                  lineHeight="1.611em"
                  align="center"
                >
                  Medical Travel Shield Australia is travel insurance designed for
                  patients travelling abroad for either Dental Treatment, Cosmetic
                  surgery, Elective surgery or Fertility Treatment.
                </Text>
              )}
              {isIE && (
                <Text
                  size="1.125em"
                  marginBottom="1.778em"
                  lineHeight="1.611em"
                  align="center"
                >
                  Medical Travel Shield is travel insurance designed for patients
                  travelling abroad for either Dental Treatment, Cosmetic surgery,
                  Elective surgery or Fertility Treatment.
                </Text>
              )}
              <Link to={`${routes.quote}${getUrlParamsAsString()}`} decoration="none">
                <CtaButton
                  width="11.278em"
                  marginLeft="auto"
                  marginRight="auto"
                  data-testid="cta"
                >
                  Get a Quote
                </CtaButton>
              </Link>
            </HeroInner>
          </HeroWrapper>
        )}
      </Header>
      <Body>
        <Columns margin="0 -2em">
          <Column margin="0 2em" display="flex">
            <Card headerText="Already Registered? Log In">
              <CardSection>
                <Text marginBottom="1.313em">
                  Access your saved quotes and/or Certificates of Insurance
                </Text>
                <Form name="loginForm" onSubmit={onLoginFormSubmit}>
                  <List margin="0 0 1.688em 0">
                    <InputField
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="john.doe@example.com"
                      errors={loginForm.errors.email}
                    />
                    <InputField
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Enter your password"
                      helperText={Text => (
                        <Text
                          onClick={showForgotPasswordModal}
                          data-testid="forgot_password_link"
                        >
                          Forgot Password?
                        </Text>
                      )}
                      errors={loginForm.errors.password}
                    />
                  </List>
                  <SecondaryButton type="submit" isLoading={isLoading}>
                    Log In
                  </SecondaryButton>
                </Form>
              </CardSection>
            </Card>
          </Column>
          {!isIE && (
            <Column margin="0 2em" display="flex">
              <Card headerText="Insurance Product Information">
                <CardSection>
                  {isUK && (
                    <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
                      <Link target="_blank" href={documents.ipidCosmetic.url}>
                        {documents.ipidCosmetic.text}
                      </Link>
                      <Link target="_blank" href={documents.ipidFertility.url}>
                        {documents.ipidFertility.text}
                      </Link>
                    </List>
                  )}
                  <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
                    {(isAU || isUK) && (
                      <Link target="_blank" href={documents.wording.url}>
                        {documents.wording.text}
                      </Link>
                    )}
                    {(isAU || isUK) && (
                      <Link target="_blank" href={documents.fertilityWording.url}>
                        {documents.fertilityWording.text}
                      </Link>
                    )}
                    {isUK && (
                      <Link target="_blank" href={documents.summary.url}>
                        {documents.summary.text}
                      </Link>
                    )}
                    {isUK && (
                      <Link target="_blank" href={documents.fertilitySummary.url}>
                        {documents.fertilitySummary.text}
                      </Link>
                    )}
                    {isAU && (
                      <Link target="_blank" href={documents.dutyOfDisclosure.url}>
                        {documents.dutyOfDisclosure.text}
                      </Link>
                    )}
                    {isAU && (
                      <Link
                        target="_blank"
                        href={documents.financialServicesGuide.url}
                      >
                        {documents.financialServicesGuide.text}
                      </Link>
                    )}
                    {isIE && <Link target="_blank" href={documents.wording.url}>{documents.wording.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.fertilityWording.url}>{documents.fertilityWording.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.summary.url}>{documents.summary.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.fertilitySummary.url}>{documents.fertilitySummary.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.ipidCosmetic.url}>{documents.ipidCosmetic.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.ipidFertility.url}>{documents.ipidFertility.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.tob.url}>{documents.tob.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.demands.url}>{documents.demands.text}</Link>}
                    {isIE && <Link target="_blank" href={documents.idd.url}>{documents.idd.text}</Link>}
                  </List>
                </CardSection>
              </Card>
            </Column>
          )}
        </Columns>
      </Body>
      <Footer />
      <Snackbar />
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: state.loginForm.isLoading || state.user.isLoading,
  loginForm: state.loginForm,
});

const mapDispatchToProps = {
  fetchUser: authActions.fetchUser,
  saveQuote: quoteActions.saveQuote,
  showModal: modalActions.showModal,
  showSnackbar: snackbarActions.showSnackbar,
  submitLoginForm: authActions.submitLoginForm,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Home),
);
